@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    height: 100vh;
}

.header-sticky {
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: white;
}

thead.header-sticky {
    top: 54px;
    z-index: 4;
    background-color: white;
}

.tabela table {
    border-collapse: separate;
    border-spacing: 1px 1px;
}

body {
    margin: 0;
    font-family: "Rubik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(243 244 246);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.input-tabela {
    appearance: none;
    background-color: #fff;
    border-width: 0px;
    border-radius: 0px;
    font-size: 0.8rem;
    line-height: 1.4rem;
    --tw-shadow: 0 0 #0000;
}

.erro .input-tabela-sm {
    background-color: #fd9797;
}

.input-tabela-sm {
    appearance: none;
    background-color: #fff;
    border-width: 0px;
    border-radius: 0px;
    font-size: 0.8rem;
    line-height: 0.7px !important;
    --tw-shadow: 0 0 #0000;
}

.input-tabela-sm.input-repetido {
    background-color: #ffeecf !important;
}

input:disabled {
    background-color: #eaeaea;
}

input:disabled.auto-preenchimento {
    background-color: #b3ffe5 !important;
}

.tabela td {
    border: 1px solid #c4c4c4;
    padding: 0px;
}
